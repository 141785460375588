jQuery(($) => {
    "use strict"

    if(!$('.wfwe-navbar').length) return

    /**
     * Variables
     */
    let submenu_open_timeout
    let li_item_string = 'li.menu-item-has-children[data-menu-depth="0"]'

    /**
     * Internal Functions
     */
    function is_desktop_mode() {
        return $(window).width() >= 1200
    }
    function is_mobile_mode() {
        return !is_desktop_mode()
    }

    function get_cta_templates() {
        if(typeof wdt_menu_cta_obj !== 'object') return
        if(!Array.isArray(wdt_menu_cta_obj.ctas) || (Array.isArray(wdt_menu_cta_obj.ctas) && wdt_menu_cta_obj.ctas.length === 0)) return


        for(let cta of wdt_menu_cta_obj.ctas) {
            let top_level_menu_item_element = $('[data-menu-depth="0"] > a > span').filter(function() {
                return $(this).text().trim() === cta.top_level_menu_item
            })
            if(!top_level_menu_item_element) continue
            top_level_menu_item_element = top_level_menu_item_element.closest('li.menu-item')

            let cta_template_html = scaffold_menu_cta_template(cta)
            if(cta_template_html === '') continue

            top_level_menu_item_element.find('.sub-menu-container').prepend(cta_template_html).closest('.sub-menu-container').addClass('has-call-to-action')
        }
    }
    
    function scaffold_menu_cta_template(cta) {
        let cta_template_html = `
            <div class="wdt-menu-cta-scaffold-template">
                <p>${cta.title}</p>
                <p>${cta.content}</p>`
                if(cta.button_link && cta.button_label) {
                    cta_template_html += `<a href="${cta.button_link}" ${cta.open_in_new_tab ? 'target="_blank"' : ''}>${cta.button_label}</a>`
                }
            cta_template_html += `</div>
        `
        return cta_template_html
    }

    function close_currently_opened_submenus(excluded_menu_item_element = false) {
        $(`#menu-main-menu ${li_item_string} a[aria-expanded="true"]`).each(function() {
            if(
                excluded_menu_item_element !== false && 
                (
                    excluded_menu_item_element[0] === $(this)[0] || 
                    excluded_menu_item_element.closest(li_item_string)[0] === $(this).closest(li_item_string)[0]
                )
            ) return true

            desktop_close_submenu($(this))
        })
    }

    function desktop_open_submenu(menu_item_element) {
        if(menu_item_element.attr('aria-expanded') === 'true') return
        // console.log('desktop_open_submenu')
        menu_item_element.attr('aria-expanded', 'true')
    }

    function desktop_close_submenu(menu_item_element) {
        if(menu_item_element.attr('aria-expanded') === 'false') return
        // console.log('desktop_close_submenu')
        menu_item_element.attr('aria-expanded', 'false')
    }

    function mobile_open_menu() {
        $('body').addClass('noscroll')
        $('.navbar-container').addClass('open')
        $('.e8-burger.e8-burger-squeeze').removeClass('collapsed')
        $('header.wdt-header').addClass('fixed')
    }
    
    function mobile_close_menu() {
        $('body').removeClass('noscroll')
        $('.navbar-container').removeClass('open')
        $('.e8-burger.e8-burger-squeeze').addClass('collapsed')
        $('header.wdt-header').removeClass('fixed')
    }

    function mobile_toggle_menu() {
        if($('.navbar-container').hasClass('open')) {
            mobile_close_menu()
        } else {
            mobile_open_menu()
        }
    }

    function mobile_close_all_submenus() {
        $(`#menu-main-menu ${li_item_string} .sub-menu-container.open`).each(function() {
            $(this).prev('a').find('span').removeClass('open')
            $(this).toggleClass('open').slideUp()
        })
    }

    function mobile_toggle_submenu(submenu_element) {
        if(submenu_element.prev('a').attr('aria-expanded') === 'false') {
            submenu_element.prev('a').attr('aria-expanded', 'true').find('span').addClass('open')
            submenu_element.addClass('open').slideDown()
        } else {
            submenu_element.prev('a').attr('aria-expanded', 'false').find('span').removeClass('open')
            submenu_element.removeClass('open').slideUp()
        }
    }

    /**
     * Events
     */
    $(window).on('resize', function() {
        if(is_desktop_mode()) return
        mobile_close_all_submenus()
    })

    $('.e8-burger-container button').on('click', function() {
        if(is_desktop_mode()) return
        mobile_toggle_menu()
    })

    $(`#menu-main-menu ${li_item_string}`).on('click', function(e) {
        if(is_desktop_mode()) return
        if($(e.target).closest('li').attr('data-menu-depth') !== '0') return
        e.preventDefault()

        let submenu_container = $(this).find('.sub-menu-container')
        if(!submenu_container.hasClass('open')) mobile_close_all_submenus()
        mobile_toggle_submenu(submenu_container)
    })

    $(`#menu-main-menu ${li_item_string} > a`).on({
        click: function(e) {
            if(is_mobile_mode()) return
            if(e.currentTarget !== this) return
            e.preventDefault()

            if($(this).attr('aria-expanded') === 'true') {
                close_currently_opened_submenus()
            } else {
                close_currently_opened_submenus($(this))
                desktop_open_submenu($(this))
            }
        },
    })

    $(document).on('click', function(e) {
        if(is_mobile_mode()) return
        if($(e.target).closest(`#menu-main-menu ${li_item_string}`).length === 0) {
            close_currently_opened_submenus()
        }
    })

    // OPEN MAIN MENU ON HOVER LOGIC
    /*
        $(`#menu-main-menu ${li_item_string}`).on({
            mouseenter: function(e) {
                if(is_mobile_mode()) return
                if(e.currentTarget !== this) return

                close_currently_opened_submenus($(this))
                clearTimeout(submenu_open_timeout)
                desktop_open_submenu($(this))
            },
            mouseleave: function(e) {
                if(is_mobile_mode()) return
                if(e.currentTarget !== this) return

                submenu_open_timeout = setTimeout(() => {
                    desktop_close_submenu($(this))
                }, 300)
            },
        })
    */

    /**
     * Call right away
     */
    get_cta_templates()
})