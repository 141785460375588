jQuery(($) => {
    "use strict"
    if($('.tabbed-post-list-container').length) {
        /**
         * Ajax call to get the posts
         */
        let tabbedPostListloadMorePosts = (post_list, args) => {
            console.log('tabbedPostListloadMorePosts')

            // Turn on spinner
            post_list.find('.load-more-button .wdt-spinner').stop().show();

            // get the query params
            let nonce = post_list.data('nonce')
            let per_page = post_list.data('posts-per-page') // get one more than we need to check if there's more to get

            let data = {
                action: 'tabbed_list_load_more_posts',
                nonce: nonce,
                post_type: ['post', 'page'],
                posts_per_page: per_page,
                offset: args.offset
            }
            if(args.search != '') data.s = args.search
            if(args.tag != '' && args.tag != undefined) data.tag = args.tag.split(',')
            if(args.post_type != '' && args.post_type != undefined) data.post_type = args.post_type.split(',')
            if(args.display != '') data.display = args.display

            // console.log(data)

            // run ajax call
            $.ajax({
                url: localized_vars.ajaxurl,
                method: 'post',
                // dataType: 'json',
                data: data
            })
            .always(() => {
                // Turn off spinner
                post_list.find('.load-more-button .wdt-spinner').stop().hide();
            })
            .error(err => {
                console.log('Error:')
                console.log(err)
            })
            .done(res => {
                console.log(res)
                // res = res.split('|*|*|*|*|*')

                // let json = JSON.parse(res[0]);
                // let posts_html = res[1];

                // // console.log(posts_html)

                // if(posts_html.indexOf('class="excerpt card') >= 0 || posts_html.indexOf('class="tabbed-post-list-item') >= 0) {

                //     // console.log(json)

                //     if(json.per_page_results == json.posts_per_page + 1) {
                //         $(`.tabbed-post-list[data-tab="${args.tab}"]`).attr('data-offset', json.new_offset )
                //         $(`.tabbed-post-list[data-tab="${args.tab}"] .tabbed-post-list-inner`).append(posts_html)
                //     } else {
                //         // remove load more button
                //         $(`.tabbed-post-list[data-tab="${args.tab}"] .tabbed-post-list-inner`).append(posts_html).siblings('.load-more-button').remove()
                //     }
                // } else {
                //     console.log('Error:')
                //     // console.log(res)
                //     console.log(json)

                //     $(`.tabbed-post-list[data-tab="${args.tab}"]`).attr('data-offset', json.new_offset )

                //     // hide the laod more button
                //     post_list.find('.load-more-button').hide();
                // }
            })
        }

        let switchPostListTab = (tab = false) => {

            let list = $('.tabbed-post-list').first()
            if(tab) {
                list = $(`.tabbed-post-list[data-tab="${tab}"]`).first()
            }

            if(!list.hasClass('active')) {
                // unset the active tab
                $(`.tabbed-post-list.active`).removeClass('active')            

                // set the current tab as active
                list.addClass('active')
            }
        }

        let initTabbedPostList = (post_list) => {
            switchPostListTab();

            /**
             * Add event listeners to the block
             */
            post_list.find('.tab').each(function() {
                // foreach tab, on click
                $(this).on('click', function() {
                    let tab = $(this).data('tab')
                    $(`.tab.active`).removeClass('active')
                    $(this).addClass('active')

                    switchPostListTab(tab)
                })
            })

            post_list.find('select').on('change', function() {
                let tab = $(this).val()
                switchPostListTab(tab)
            })
            
            post_list.find('.tabbed-post-list .load-more-button').on('click', function(e) {
                e.preventDefault()
                
                let args = {}

                args.tab = $(this).closest('.tabbed-post-list').attr('data-tab')
                args.tag = $(this).closest('.tabbed-post-list').attr('data-tag')
                args.post_type = $(this).closest('.tabbed-post-list').attr('data-post-type')
                args.offset = $(this).closest('.tabbed-post-list').attr('data-offset')
                args.search = post_list.attr('data-search-query');
                args.display = post_list.attr('data-display');

                tabbedPostListloadMorePosts(post_list, args);
            })
        }
    
        $('.tabbed-post-list-container').each(function() {
            initTabbedPostList($(this))
        })
    }
})