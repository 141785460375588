jQuery(($) => {
    "use strict"

    if(!$('.google-language-selector').length) return

    let get_supported_languages = () => {
        let iframe_window = get_iframe_window()
        let supported_langauges = []
        $(iframe_window.document).find('table > tbody > tr a').each(function() {
            if($(this).text().replaceAll('›', '') === 'Select Language') return true
            supported_langauges.push($(this).text().replaceAll('›', ''))
        })
        return supported_langauges
    }

    let get_iframe_window = () => {
        let iframe = $('.VIpgJd-ZVi9od-aZ2wEe-wOHMyf + iframe')[0]
        return iframe.contentWindow
    }

    let select_google_translate_language = (language, focus_on_language_selector = true) => {
        let iframe_window = get_iframe_window()
        $('.google-language-selector .language-dropdown').attr('data-language', language)
        close_language_selector()
        if(focus_on_language_selector) {
            $('.language-dropdown-toggle')[0].focus()
        }

        if(language === 'English') {
            let iframe = document.getElementById(':2.container')
            if(iframe === null) return
            iframe_window = iframe.contentWindow
            iframe_window.document.getElementById(':2.restore').click()
        } else {
            if($(iframe_window.document).find(`table > tbody > tr a:contains("${language}")`).length === 0) {
                console.warn(`Language ${language} is not supported.`)
                // console.log('Here is a list of supported langauges:')
                // console.log(get_supported_languages())
                return
            }
            $(iframe_window.document).find(`table > tbody > tr a:contains("${language}")`)[0].click()
        }
    }
    let check_page_language = () => {
        let current_language = $('.VIpgJd-ZVi9od-xl07Ob-lTBxed > span:first-child').text()
        let language_select_option = $(`.google-language-selector .language-dropdown button[data-language="${current_language}"]`)
        if(language_select_option.length) {
            $('.google-language-selector .language-dropdown button.active').removeClass('active').attr('aria-selected', 'false')
            language_select_option.addClass('active').attr('aria-selected', 'true')
            $('.google-language-selector .language-dropdown').attr('data-language', current_language)
        } else {
            select_google_translate_language('English', false)
        }
    }
    let interval_count = 0
    let interval = setInterval(function() {
        interval_count++
        if($('.VIpgJd-ZVi9od-xl07Ob-lTBxed > span:first-child').length > 0) {
            clearInterval(interval)
            check_page_language()
        }

        if(interval_count >= 25) {
            clearInterval(interval)
            console.error('Google Language Selector element not found after 25 attempts.')
        }
    }, 200)
    let close_language_selector = () => {
        $('.google-language-selector').removeClass('open')
        $('.language-dropdown-toggle').attr('aria-expanded', 'false')
    }
    
    $('.google-language-selector .language-dropdown button').on('click', function() {
        if(!$(this).hasClass('active')) $('.google-language-selector .language-dropdown button.active').removeClass('active').attr('aria-selected', 'false')
        $(this).addClass('active').attr('aria-selected', 'true')
        select_google_translate_language($(this).attr('data-language'))
    })
    $('.language-dropdown-toggle').on('click', function() {
        if($('.google-language-selector').hasClass('open')) {
            close_language_selector()
            return
        }
        $('.google-language-selector').addClass('open')
        $('.language-dropdown-toggle').attr('aria-expanded', 'true')
    })
    $('.language-dropdown-toggle').on('keydown', function(e) {
        if(e.key === 'ArrowDown') {
            e.preventDefault()
            $('.google-language-selector').addClass('open')
            $('.language-dropdown-toggle').attr('aria-expanded', 'true')
            $('.language-dropdown button:first-child')[0].focus()
        }
    })
    $('.language-dropdown').on('keydown', function(e) {
        if(e.key === 'Escape' && $('.google-language-selector').hasClass('open')) {
            close_language_selector()
            $('.language-dropdown-toggle')[0].focus()
        }
        if(e.key === 'ArrowDown') {
            e.preventDefault()
            if($(document.activeElement).next().length) {
                $(document.activeElement).next()[0].focus()
            }
        }
        if(e.key === 'ArrowUp') {
            e.preventDefault()
            if($(document.activeElement).prev().length) {
                $(document.activeElement).prev()[0].focus()
            }
        }
        if(e.key === 'Home') {
            e.preventDefault()
            if($(document.activeElement).parent().children().first().length) {
                $(document.activeElement).parent().children().first()[0].focus()
            }
        }
        if(e.key === 'End') {
            e.preventDefault()
            if($(document.activeElement).parent().children().last().length) {
                $(document.activeElement).parent().children().last()[0].focus()
            }
        }
    })
    $('.language-dropdown button:first-child').on('keydown', function(e) {
        if(e.key === 'Tab' && e.shiftKey) {
            close_language_selector()
        }
    })
    $('.language-dropdown button:last-child').on('keydown', function(e) {
        if(e.key === 'Tab' && !e.shiftKey) {
            close_language_selector()
        }
    })
    $(document).on('click', function(e) {
        if($(e.target).closest('.google-language-selector').length || $(e.target).hasClass('google-language-selector')) return

        if($('.google-language-selector').hasClass('open')) {
            close_language_selector()
        }
    })
    $(document).on('keydown', function(e) {
        if(e.key === 'Escape') {
            if($(e.target).closest('.google-language-selector').length || $(e.target).hasClass('google-language-selector')) return
    
            if($('.google-language-selector').hasClass('open')) {
                close_language_selector()
            }
        }
    })
})